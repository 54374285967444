import { Box, Button, CircularProgress, FormControl, Typography, MenuItem, Select, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../../Common/SectionHeader";
import { GiGavel } from "react-icons/gi";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import "rsuite/dist/rsuite.min.css";
import ContactTableReport from "./ContactTableReport";
import { images } from "../../Assets";
import { IoClose } from "react-icons/io5";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ScrollableContainer from "../../Common/ScrollableContainer";
import { useGetTableViewQuery, useUpdateTableViewMutation } from "../../../redux/slices/api/tableView/tableViewApi";
import { setSnackbar } from "../../../redux/slices/app";
import { LoadingButton } from "@mui/lab";
import ConfirmDeleteTableView from "../../Common/ConfirmDeleteTableView";
import { useGetAllContactsQuery } from "../../../redux/slices/api/contacts/contactApi";

const ContactReportSection = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();

	const [contactType, setContactType] = useState("All");
	const [contactCategory, setContactCategory] = useState("All");
	const [colFilter, setColFilter] = useState([]);
	const [columnSizing, setColumnSizing] = useState({});
	const [columnVisibility, setColumnVisibility] = useState({});
	const [columnOrder, setColumnOrder] = useState([]);
	const [defaultTemplate, setDefaultTemplate] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [handleDeleteView, setHandleDeleteView] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 50,
	});

	const colFilterObject = colFilter.reduce((acc, curr) => {
		return acc;
	}, {});

	const contactQuery = {
		...(contactType !== "All" && { type: contactType }),
		...(contactCategory !== "All" && { category: contactCategory }),
		...(colFilter.length > 0 && { ...colFilterObject }),
		skip: pagination.pageIndex * pagination.pageSize,
		limit: pagination.pageSize,
	};

	const { data: contactData, isLoading: contactLoading, isError: contactIsError } = useGetAllContactsQuery(contactQuery, { refetchOnMountOrArgChange: true });
	const [rows, setRows] = useState([]);
	const [handleOpenDeleteDialog, setHandleOpenDeleteDialog] = useState(false);
	const [totalCount, setTotalCount] = useState(contactData?.totalRecords || 0);

	useEffect(() => {
		if (contactIsError) return;

		if (contactData && !contactLoading && !contactIsError) {
			setRows(contactData?.data);
			setTotalCount(contactData?.totalRecords);
		}
	}, [contactData, pagination.pageIndex, pagination.pageSize, contactType, contactCategory, searchQuery, colFilter]);

	const { data: tableViewData } = useGetTableViewQuery({ type: "contact" }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (tableViewData?.data?.contact) {
			const defaultView = tableViewData?.data?.contact?.find((view) => view.default === true);
			if (defaultView) {
				setDefaultTemplate(defaultView);
				setSelectedTemplate(defaultView);
				setColumnVisibility(JSON.parse(defaultView?.column));
				setColumnOrder(JSON.parse(defaultView?.columnOrder));
			} else {
				const defaultTemp = tableViewData?.data?.contact[0];
				setSelectedTemplate(defaultTemp);
				setColumnVisibility(JSON.parse(defaultTemp?.column));
				setColumnOrder(JSON.parse(defaultTemp?.columnOrder));
			}
			setTemplates(tableViewData?.data?.contact);
		} else {
			setSelectedTemplate(null);
			setColumnVisibility({});
			setColumnOrder([]);
		}
	}, [tableViewData, templates]);

	const [updateTableView] = useUpdateTableViewMutation();

	const handleSetAsDefault = async (e) => {
		e.preventDefault();
		setLoading(true);
		const data = {
			default: true,
		};
		try {
			await updateTableView({ templateId: selectedTemplate?.id, data: data }).unwrap();
			setDefaultTemplate(selectedTemplate);
			setSelectedTemplate(selectedTemplate);
			setLoading(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Box
			width={"100%"}
			height={"max-content"}
			p={2}
			borderRadius={2}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
		>
			<SectionHeader icon={<GiGavel color="#164c68" size={18} />} title={`${t("My")} ${t("Cases")}`} section={"cases"} type={"dialog"} />
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1} my={2}>
				<Stack width={"100%"} my={2} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
					<Stack direction={"row"} justifyContent={"start"} alignItems={"center"} gap={1}>
						<Typography variant={"h5"} color={"#164c68"}>
							{t("ContactType")}:
						</Typography>
						<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
							<Select
								id="select-type"
								value={contactType}
								onChange={(e) => setContactType(e?.target?.value)}
								sx={{ color: "#164c68" }}
								displayEmpty
							>
								<MenuItem value={"All"}>{t("All")}</MenuItem>
								<MenuItem value={"individual"}>{t("Individual")}</MenuItem>
								<MenuItem value={"company"}>{t("Company")}</MenuItem>
							</Select>
						</FormControl>
					</Stack>
					<Stack direction={"row"} justifyContent={"start"} alignItems={"center"} gap={1}>
						<Typography variant={"h5"} color={"#164c68"}>
							{t("ContactCategory")}:
						</Typography>
						<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
							<Select
								id="select-category"
								value={contactCategory}
								onChange={(e) => setContactCategory(e?.target?.value)}
								sx={{ color: "#164c68" }}
								displayEmpty
							>
								<MenuItem value={"All"}>{t("All")}</MenuItem>
								<MenuItem value={"client"}>{t("Client")}</MenuItem>
								<MenuItem value={"litigant"}>{t("Litigant")}</MenuItem>
								<MenuItem value={"lawyer"}>{t("Lawyer")}</MenuItem>
								<MenuItem value={"expertOffice"}>{t("ExpertOffice")}</MenuItem>
							</Select>
						</FormControl>
					</Stack>

					<Typography variant={"h5"} color={"#164c68"}>
						{t("SelectedTemplate")}:
					</Typography>
					{templates?.length > 0 ? (
						<FormControl sx={{ m: 1, minWidth: 100, mr: isRtl ? "8px !important" : 0 }} size="small">
							<Select
								id="select-template"
								value={selectedTemplate}
								onChange={(e) => {
									setSelectedTemplate(e?.target?.value);
									setColumnVisibility(JSON.parse(e?.target?.value?.column));
									setColumnOrder(JSON.parse(e?.target?.value?.columnOrder));
								}}
								sx={{ color: "#164c68" }}
								displayEmpty
							>
								{defaultTemplate && (
									<MenuItem value={defaultTemplate}>
										{defaultTemplate?.name} ({t("Default")})
									</MenuItem>
								)}
								{templates
									?.filter((template) => defaultTemplate?.id !== template.id)
									.map((template) => (
										<MenuItem key={template.id} value={template}>
											{template?.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					) : (
						<Typography variant={"h5"} color={"#164c68"}>
							{t("NoTableViewFound")}
						</Typography>
					)}
					{selectedTemplate?.id !== defaultTemplate?.id && (
						<LoadingButton
							loading={contactLoading}
							variant="contained"
							size="small"
							onClick={handleSetAsDefault}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								padding: 1,
								"&:hover": {
									bgcolor: "#00a3b1",
								},
								transition: "all 0.5s ease",
							}}
						>
							{t("SetAsDefault")}
						</LoadingButton>
					)}
				</Stack>
			</Stack>

			<Box height={"100%"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} pb={2}>
				{contactLoading && !contactIsError ? (
					<CircularProgress />
				) : rows ? (
					<ScrollableContainer>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<ContactTableReport
								data={rows}
								totalCount={totalCount}
								// deleteCase={() => setHandleOpenDeleteDialog(true)}
								pagination={pagination}
								setPagination={setPagination}
								pageCount={Math.ceil(totalCount / pagination.pageSize)}
								searchQuery={searchQuery}
								setSearchQuery={setSearchQuery}
								colFilter={colFilter}
								setColFilter={setColFilter}
								columnVisibility={columnVisibility}
								setColumnVisibility={setColumnVisibility}
								columnSizing={columnSizing}
								setColumnSizing={setColumnSizing}
								columnOrder={columnOrder}
								setColumnOrder={setColumnOrder}
								setDefaultView={setDefaultTemplate}
								selectedTemplate={selectedTemplate}
								setSelectedTemplate={setSelectedTemplate}
								templates={templates}
								setHandleDeleteView={setHandleDeleteView}
								loading={contactLoading}
							/>
						</LocalizationProvider>
					</ScrollableContainer>
				) : (
					<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={10}>
						<img src={images.notFound} alt="Not Found" width={200} height={200} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
							{t("NoCaseFileFound")}
						</Typography>
						<Button
							size="small"
							endIcon={!isRtl && <IoClose color="red" />}
							onClick={() => {
								setSearchQuery("");
								setColFilter([]);
							}}
							sx={{
								bgcolor: "#fff",
								color: "#164c68",
								padding: 1,
								border: "1px solid #164c68",
							}}
						>
							{t("ClearSearch")}
						</Button>
					</Box>
				)}
			</Box>
			{/* {handleOpenDeleteDialog && <DeleteCaseDialog open={handleOpenDeleteDialog} handleClose={() => setHandleOpenDeleteDialog(false)} />}
			{handleDeleteView && (
				<ConfirmDeleteTableView
					open={handleDeleteView}
					handleClose={() => setHandleDeleteView(false)}
					templateId={selectedTemplate?.id}
					template={selectedTemplate}
					setTemplates={setTemplates}
					setSelectedTemplate={() => setSelectedTemplate(null)}
					type={"caseFile"}
					setColumnVisibility={setColumnVisibility}
					setColumnOrder={setColumnOrder}
					setDefaultView={setDefaultTemplate}
				/>
			)} */}
		</Box>
	);
};

export default ContactReportSection;
