import React, { useMemo, useState } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { setCurrentMeeting, setIsCreatingMeeting } from "../../../redux/slices/meetings";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { useGetAllUsersQuery } from "../../../redux/slices/api/user/userApi";
import { useGetByCriteriaQuery } from "../../../redux/slices/api/contacts/contactApi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { excelExport, handleConvertToPdf, meetingFilePreProcessor } from "../../../utils/exportHelper";
import { SiMicrosoftexcel } from "react-icons/si";
import SaveTableView from "../../Common/SaveTableView";
import { useGetConstraintsQuery } from "../../../redux/slices/api/constraints/constraintsApi";
import { setCurrentCase } from "../../../redux/slices/case";
import axiosInstance from "../../../utils/axios";

const MeetingTableReport = ({
	data,
	totalCount,
	pagination,
	setPagination,
	pageCount,
	searchQuery,
	setSearchQuery,
	colFilter,
	setColFilter,
	columnVisibility,
	setColumnVisibility,
	columnSizing,
	setColumnSizing,
	columnOrder,
	setColumnOrder,
	setDefaultView,
	selectedTemplate,
	setSelectedTemplate,
	setHandleDeleteView,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);
	const cases = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });
	const users = useGetAllUsersQuery({}, { refetchOnMountOrArgChange: true });
	const contact = useGetByCriteriaQuery({}, { refetchOnMountOrArgChange: true });
	const caseTypeConstraint = useGetConstraintsQuery({ reference: "caseType" }, { refetchOnMountOrArgChange: true });
	const courtConstraint = useGetConstraintsQuery({ reference: "court" }, { refetchOnMountOrArgChange: true });
	const party = useGetByCriteriaQuery({}, { refetchOnMountOrArgChange: true });

	const [isLoading, setIsLoading] = useState(false);

	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => (row.caseFile ? row.caseFile.id : "N/A"),
				id: "caseFile",
				header: t("FileNumber"),
				filterVariant: "autocomplete",
				filterSelectOptions: cases.data?.data?.map((c) => ({ value: c.id, label: c.fileNumber.toString() })),
				// size: columnSizing?.caseFile || 300,
				Cell: ({ renderedCellValue, row }) => {
					const caseFileNumber = row?.original?.caseFile ? row?.original?.caseFile?.fileNumber.toString() : "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{caseFileNumber}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => (row.caseFile ? row.caseFile.id : "N/A"),
				id: "caseFile.caseNumber",
				header: t("CaseFileFullName"),
				filterVariant: "autocomplete",
				filterSelectOptions: cases.data?.data?.map((c) => ({ value: c.id, label: c.caseNumber?.toString() })),
				// size: columnSizing?.["caseFile.caseNumber"] || 300,
				Cell: ({ renderedCellValue, row }) => {
					const caseFileNumber = row?.original?.caseFile
						? row?.original?.caseFile?.classification === "litigation"
							? `${row?.original?.caseFile?.caseNumber}/${row?.original?.caseFile?.caseYear}`
							: "N/A"
						: "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{caseFileNumber}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => new Date(row?.date).toLocaleDateString(),
				id: "date",
				header: t("Date"),
				enableColumnFilter: false,
				// size: columnSizing?.date || 300,
				Cell: ({ renderedCellValue, row }) => {
					const date = row?.original?.date ? moment(row?.original?.date).format("YYYY/MM/DD") : "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{date}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => new Date(row?.date).toLocaleDateString(),
				id: "day",
				header: t("Day"),
				enableColumnFilter: false,
				// size: columnSizing?.day || 300,
				Cell: ({ renderedCellValue, row }) => {
					const date = row?.original?.date ? moment(row?.original?.date).format("dddd") : "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{date}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => new Date(row?.date).toLocaleDateString(),
				id: "time",
				header: t("Time"),
				enableColumnFilter: false,
				// size: columnSizing?.time || 300,
				Cell: ({ renderedCellValue, row }) => {
					const date = row?.original?.date ? moment(row?.original?.date).format("LTS") : "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{date}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => row?.caseFile?.caseType?.id,
				accessorKey: "caseType",
				header: t("CaseType"),
				filterVariant: "select",
				filterSelectOptions: caseTypeConstraint.data?.data?.map((constraint) => ({
					value: constraint?.id,
					label: isRtl ? constraint?.nameAr : constraint?.nameEn ? constraint?.nameEn : constraint?.nameAr,
				})),
				// size: columnSizing?.caseType || 300,
				Cell: ({ renderedCellValue, row }) => {
					const caseType =
						row?.original?.caseFile?.classification === "litigation"
							? isRtl
								? row?.original?.caseFile?.caseType?.nameAr
								: row?.original?.caseFile?.caseType?.nameEn
								? row?.original?.caseFile?.caseType?.nameEn
								: row?.original?.caseFile?.caseType?.nameAr
							: "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{caseType}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => row?.caseFile?.court?.id,
				accessorKey: "court",
				header: t("Court"),
				enableColumnFilter: false,
				// size: columnSizing?.court || 300,
				Cell: ({ renderedCellValue, row }) => {
					const court =
						row?.original?.caseFile?.classification === "litigation"
							? isRtl
								? row?.original?.caseFile?.court?.nameAr
								: row?.original?.caseFile?.court?.nameEn
								? row?.original?.caseFile?.court?.nameEn
								: row?.original?.caseFile?.court?.nameAr
							: "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{court}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => row?.caseFile?.parties?.map((party) => party?.party?.id).join(", "),
				header: t("Parties"),
				accessorKey: "parties",
				filterVariant: "autocomplete",
				enableColumnFilter: false,
				filterSelectOptions: party?.data?.data?.map((party) => ({
					value: party?.id,
					label: isRtl ? party?.nameAr : party?.nameEn ? party?.nameEn : party?.nameAr,
				})),
				// size: columnSizing?.parties || 400,
				Cell: ({ renderedCellValue, row }) => {
					const parties = row?.original?.caseFile?.parties
						? row?.original?.caseFile?.parties
								?.map((party) => (isRtl ? party?.party?.nameAr : party?.party?.nameEn ? party?.party?.nameEn : party?.party?.nameAr))
								.join(", ")
						: "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{parties}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => row?.caseFile?.userByPosition?.Expert?.map((user) => user?.id).join(", "),
				header: t("Experts"),
				accessorKey: "Expert",
				filterVariant: "autocomplete",
				enableColumnFilter: false,
				filterSelectOptions: users?.data?.data?.map((user) => ({
					value: user?.id,
					label: user?.fullName,
				})),
				// size: columnSizing?.Expert || 400,
				Cell: ({ renderedCellValue, row }) => {
					const experts = row?.original?.caseFile?.userByPosition?.Expert
						? row?.original?.caseFile?.userByPosition?.Expert?.map((user) => user?.fullName).join(", ")
						: "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{experts}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => row?.caseFile?.userByPosition?.["Expert Assistant"]?.map((user) => user?.id).join(", "),
				header: t("ExpertAssistants"),
				accessorKey: "Expert Assistant",
				enableColumnFilter: false,
				filterVariant: "autocomplete",
				filterSelectOptions: users?.data?.data?.map((user) => ({
					value: user?.id,
					label: user?.fullName,
				})),
				// size: columnSizing?.["Expert Assistant"] || 400,
				Cell: ({ renderedCellValue, row }) => {
					const expertAssistants = row?.original?.caseFile?.userByPosition?.["Expert Assistant"]
						? row?.original?.caseFile?.userByPosition?.["Expert Assistant"]?.map((user) => user?.fullName).join(", ")
						: "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{expertAssistants}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorKey: "code",
				id: "code",
				header: t("MeetingCode"),
				// size: columnSizing?.code || 300,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
							{renderedCellValue}
						</Typography>
					</Box>
				),
			},
			{
				accessorFn: (row) => (row?.note ? row?.note : "N/A"),
				header: t("Note"),
				accessorKey: "note",
				// size: columnSizing?.note || 500,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{renderedCellValue.length > 50 ? `${renderedCellValue.substring(0, 50)}...` : renderedCellValue}
						</Typography>
					</Box>
				),
			},
			{
				accessorFn: (row) => (row.url ? row.url : ""),
				accessorKey: "URL",
				enableColumnFilter: false,
				header: t("Url"),
				// size: columnSizing?.URL || 300,
				// 	grow: true,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						{renderedCellValue !== "" ? (
							<Link href={renderedCellValue} target="_blank" rel="noopener noreferrer">
								{renderedCellValue.length > 50 ? `${renderedCellValue.substring(0, 50)}...` : renderedCellValue}
							</Link>
						) : (
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
								N/A
							</Typography>
						)}
					</Box>
				),
			},
			{
				accessorFn: (row) => row.users?.map((user) => user.id).join(", "),
				header: t("MeetingUsers"),
				accessorKey: "users",
				filterVariant: "autocomplete",
				filterSelectOptions: users?.data?.data?.map((user) => ({
					value: user?.id,
					label: user?.fullName,
				})),
				// size: columnSizing?.users || 550,
				Cell: ({ renderedCellValue, row }) => {
					const users = row?.original?.users?.map((user) => user?.fullName).join("-") || "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
								{users}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => row.contacts?.map((contact) => contact.id).join(", "),
				header: t("MeetingContacts"),
				accessorKey: "contacts",
				filterVariant: "autocomplete",
				filterSelectOptions: contact?.data?.data?.map((contact) => ({
					value: contact?.id,
					label: isRtl ? contact?.nameAr : contact?.nameEn ? contact?.nameEn : contact?.nameAr,
				})),
				// size: columnSizing?.contacts || 550,
				grow: true,
				Cell: ({ renderedCellValue, row }) => {
					const contacts = row?.original?.contacts
						? row?.original?.contacts?.map((contact) => (isRtl ? contact?.nameAr : contact?.nameEn ? contact?.nameEn : contact?.nameAr)).join("-")
						: "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
								{contacts}
							</Typography>
						</Box>
					);
				},
			},
		],
		[]
	);

	const table = useMaterialReactTable({
		columns,
		data,
		columnFilterDisplayMode: "popover",
		muiTableHeadCellProps: {
			align: "center",
			sx: {
				color: "#164c68",
				"& .css-d0cm6k .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionAsc": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionDesc": {
					color: "#164c68 !important",
				},
			},
		},
		muiTableBodyCellProps: {
			align: "center",
		},
		enableStickyHeader: true,
		enableColumnResizing: true,
		enableFacetedValues: true,
		enableColumnDragging: true,
		enableColumnOrdering: true,
		layoutMode: "semantic",
		initialState: { density: "compact" },
		enableFullScreenToggle: false,
		enableRowActions: false,
		paginationDisplayMode: "pages",
		muiTableBodyRowProps: ({ row }) => ({
			sx: {
				backgroundColor: `${
					moment(row.original.date).isBefore(moment()) ? "#ffff0066" : moment(row.original.date).isAfter(moment()) ? "#03bbef3b" : "#00ff008f"
				} !important`,
				color: "#fff !important",
			},
			onClick: () => {
				dispatch(setCurrentMeeting(row.original));
				dispatch(setCurrentCase(row.original.caseFile));
				dispatch(setIsCreatingMeeting(false));
				navigate(`/dashboard/meeting-detail`);
			},
		}),
		manualPagination: true,
		manualFiltering: true,
		muiPaginationProps: {
			color: "primary",
			rowsPerPageOptions: [10, 20, 30, 50, 100],
			shape: "rounded",
			variant: "outlined",
		},
		rowCount: pagination.pageSize,
		pageCount: pageCount,
		onPaginationChange: setPagination,
		onGlobalFilterChange: setSearchQuery,
		onColumnFiltersChange: setColFilter,
		onColumnVisibilityChange: setColumnVisibility,
		onColumnOrderChange: setColumnOrder,
		onColumnSizingChange: setColumnSizing,
		state: {
			pagination,
			searchQuery,
			colFilter,
			columnVisibility,
			columnOrder,
			columnSizing,
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} gap={1}>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <AiOutlineFilePdf color="#fff" />}
					onClick={async () => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter((column) => notReorderedColumns?.includes(column) && column !== "mrt-row-spacer");
						const formattedVisibleColumns = reorderedColumns.map((column) => {
							let columnHeaderName = column;
							if (columnHeaderName === "caseFile") {
								columnHeaderName = t("FileNumber");
							}
							if (columnHeaderName === "caseFile.caseNumber") {
								columnHeaderName = t("CaseFileFullName");
							}
							if (columnHeaderName === "date") {
								columnHeaderName = t("Date");
							}
							if (columnHeaderName === "day") {
								columnHeaderName = t("Day");
							}
							if (columnHeaderName === "time") {
								columnHeaderName = t("Time");
							}
							if (columnHeaderName === "code") {
								columnHeaderName = t("MeetingCode");
							}
							if (columnHeaderName === "URL") {
								columnHeaderName = t("Url");
							}
							if (columnHeaderName === "users") {
								columnHeaderName = t("MeetingUsers");
							}
							if (columnHeaderName === "contacts") {
								columnHeaderName = t("MeetingContacts");
							}
							if (columnHeaderName === "Expert") {
								columnHeaderName = t("Experts");
							}
							if (columnHeaderName === "Expert Assistant") {
								columnHeaderName = t("ExpertAssistants");
							}
							if (columnHeaderName === "note") {
								columnHeaderName = t("Note");
							}
							if (columnHeaderName === "parties") {
								columnHeaderName = t("Parties");
							}
							if (columnHeaderName === "court") {
								columnHeaderName = t("Court");
							}
							if (columnHeaderName === "caseType") {
								columnHeaderName = t("CaseType");
							}
							return { title: columnHeaderName, dataKey: columnHeaderName };
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						while (rows.length < totalCount) {
							const skip = rows.length;
							const nextPage = await axiosInstance.get(`meeting?skip=${skip}&limit=50`);
							rows.push(...nextPage?.data?.data);
						}
						const dataToConvert = meetingFilePreProcessor(rows, visibleColumns, isRtl);
						const reorderedData = dataToConvert.map((obj) => {
							let reorderedObj = {};
							reorderedColumns.forEach((key) => {
								reorderedObj[key] = obj[key];
							});
							return reorderedObj;
						});
						handleConvertToPdf(formattedVisibleColumns, reorderedData, "Meeting Report.pdf", isRtl);
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
						marginLeft: `${isRtl ? "16px" : "0px"} !important`,
					}}
				>
					{t("ExportPdf")}
				</Button>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <SiMicrosoftexcel color="#fff" />}
					onClick={async () => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter((column) => notReorderedColumns?.includes(column) && column !== "mrt-row-spacer");
						const formattedVisibleColumns = reorderedColumns.map((column) => {
							let columnHeaderName = column;
							if (columnHeaderName === "caseFile") {
								columnHeaderName = t("FileNumber");
							}
							if (columnHeaderName === "caseFile.caseNumber") {
								columnHeaderName = t("CaseFileFullName");
							}
							if (columnHeaderName === "date") {
								columnHeaderName = t("Date");
							}
							if (columnHeaderName === "day") {
								columnHeaderName = t("Day");
							}
							if (columnHeaderName === "time") {
								columnHeaderName = t("Time");
							}
							if (columnHeaderName === "code") {
								columnHeaderName = t("MeetingCode");
							}
							if (columnHeaderName === "URL") {
								columnHeaderName = t("Url");
							}
							if (columnHeaderName === "users") {
								columnHeaderName = t("MeetingUsers");
							}
							if (columnHeaderName === "contacts") {
								columnHeaderName = t("MeetingContacts");
							}
							if (columnHeaderName === "Expert") {
								columnHeaderName = t("Experts");
							}
							if (columnHeaderName === "Expert Assistant") {
								columnHeaderName = t("ExpertAssistants");
							}
							if (columnHeaderName === "note") {
								columnHeaderName = t("Note");
							}
							if (columnHeaderName === "parties") {
								columnHeaderName = t("Parties");
							}
							if (columnHeaderName === "court") {
								columnHeaderName = t("Court");
							}
							if (columnHeaderName === "caseType") {
								columnHeaderName = t("CaseType");
							}
							return columnHeaderName;
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						while (rows.length < totalCount) {
							const skip = rows.length;
							const nextPage = await axiosInstance.get(`meeting?skip=${skip}&limit=50`);
							rows.push(...nextPage?.data?.data);
						}
						const dataToConvert = meetingFilePreProcessor(rows, visibleColumns, isRtl);
						const formattedData = dataToConvert.map((row) => {
							let formattedRow = {};
							for (let key in row) {
								let newKey = key;
								if (key === "caseFile.caseNumber") {
									newKey = t("CaseFileFullName");
								}
								if (key === "date") {
									newKey = t("Date");
								}
								if (key === "day") {
									newKey = t("Day");
								}
								if (key === "time") {
									newKey = t("Time");
								}
								if (key === "caseType") {
									newKey = t("CaseType");
								}
								if (key === "court") {
									newKey = t("Court");
								}
								if (key === "parties") {
									newKey = t("Parties");
								}
								if (key === "Expert") {
									newKey = t("Experts");
								}
								if (key === "Expert Assistant") {
									newKey = t("ExpertAssistants");
								}
								if (key === "code") {
									newKey = t("MeetingCode");
								}
								if (key === "note") {
									newKey = t("Note");
								}
								if (key === "URL") {
									newKey = t("Url");
								}
								formattedRow[newKey] = row[key];
							}
							return formattedRow;
						});
						excelExport(formattedData, formattedVisibleColumns, "Meeting Report", "Meeting Report.xlsx");
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
					}}
				>
					{t("ExportExcel")}
				</Button>
				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"meeting"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"update"}
				/>
				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"meeting"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"save"}
				/>
				<Button
					onClick={() => setHandleDeleteView(true)}
					disabled={isLoading}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
						padding: 1,
					}}
				>
					{t("DeleteTableView")}
				</Button>
				{colFilter?.length > 0 && (
					<Button
						onClick={() => setColFilter([])}
						disabled={isLoading}
						sx={{
							color: "#fff",
							backgroundColor: "#fc6739",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
							padding: 1,
						}}
					>
						{t("ClearFilter")}
					</Button>
				)}
			</Box>
		),
	});

	return <MaterialReactTable table={table} />;
};

export default MeetingTableReport;
