import { Box, Button, Typography } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AiOutlineFilePdf } from "react-icons/ai";
import { caseFilePreProcessor, contactFilePreProcessor, excelExport, handleConvertToPdf } from "../../../utils/exportHelper";
import { SiMicrosoftexcel } from "react-icons/si";
import SaveTableView from "../../Common/SaveTableView";
import axiosInstance from "../../../utils/axios";

const ContactTableReport = ({
	data,
	totalCount,
	pagination,
	setPagination,
	pageCount,
	searchQuery,
	setSearchQuery,
	colFilter,
	setColFilter,
	columnVisibility,
	setColumnVisibility,
	columnSizing,
	setColumnSizing,
	columnOrder,
	setColumnOrder,
	setDefaultView,
	selectedTemplate,
	setSelectedTemplate,
	setHandleDeleteView,
}) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => (isRtl ? row?.nameAr : row?.nameEn ? row?.nameEn : row?.nameAr),
				accessorKey: "name",
				header: t("Name"),
				Cell: ({ renderedCellValue, row }) => {
					const contactName = isRtl ? row.original?.nameAr : row.original?.nameEn ? row.original?.nameEn : row.original?.nameAr;
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{contactName}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorKey: "type",
				header: t("Type"),
				filterVariant: "select",
				filterSelectOptions: [
					{ value: "individual", label: t("Individual") },
					{ value: "company", label: t("Company") },
				],
				// size: columnSizing?.caseNumber || 300,
				Cell: ({ renderedCellValue, row }) => {
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{renderedCellValue}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorKey: "category",
				header: t("Category"),
				filterVariant: "select",
				filterSelectOptions: [
					{ value: "client", label: t("Client") },
					{ value: "litigant", label: t("Litigant") },
					{ value: "lawyer", label: t("Lawyer") },
					{ value: "expertOffice", label: t("expertOffice") },
				],
				// size: columnSizing?.caseNumber || 300,
				Cell: ({ renderedCellValue, row }) => {
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{renderedCellValue}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorKey: "phone",
				header: t("PhoneNumber"),
				Cell: ({ renderedCellValue, row }) => {
					const phoneNumber = row.original?.phone?.length === 0 ? "N/A" : row?.original?.phone?.map((phone) => phone).join(", ");

					return (
						<Box sx={{ padding: "5px" }}>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{phoneNumber}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorKey: "email",
				header: t("Email"),
				Cell: ({ renderedCellValue, row }) => {
					const emailAddress = row.original?.email?.length === 0 ? "N/A" : row?.original?.email?.map((email) => email).join(", ");

					return (
						<Box sx={{ padding: "5px" }}>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{emailAddress}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => (row?.address ? row?.address : "N/A"),
				header: t("Address"),
				accessorKey: "address",
				// size: columnSizing?.note || 600,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{renderedCellValue.length > 50 ? `${renderedCellValue.substring(0, 50)}...` : renderedCellValue}
						</Typography>
					</Box>
				),
			},
			{
				accessorFn: (row) => (row?.note ? row?.note : "N/A"),
				header: t("Note"),
				accessorKey: "note",
				// size: columnSizing?.note || 600,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{renderedCellValue.length > 50 ? `${renderedCellValue.substring(0, 50)}...` : renderedCellValue}
						</Typography>
					</Box>
				),
			},
		],
		[]
	);

	const table = useMaterialReactTable({
		columns,
		data,
		columnFilterDisplayMode: "popover",
		muiTableHeadCellProps: {
			align: "center",
			sx: {
				color: "#164c68",
				"& .css-d0cm6k .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionAsc": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionDesc": {
					color: "#164c68 !important",
				},
			},
		},
		muiTableBodyCellProps: {
			align: "center",
		},
		enableStickyHeader: true,
		enableColumnResizing: true,
		enableColumnDragging: true,
		enableColumnOrdering: true,
		enableFacetedValues: true,
		layoutMode: "semantic",
		columnResizeMode: "onEnd",
		initialState: { density: "compact" },
		enableFullScreenToggle: false,
		enableRowActions: false,
		paginationDisplayMode: "pages",
		positionToolbarAlertBanner: "bottom",
		muiSearchTextFieldProps: {
			// size: "small",
			variant: "outlined",
		},
		manualPagination: true,
		manualFiltering: true,
		muiPaginationProps: {
			color: "primary",
			rowsPerPageOptions: [10, 20, 30, 50, 100],
			shape: "rounded",
			variant: "outlined",
		},
		rowCount: pagination.pageSize,
		pageCount: pageCount,
		muiTableBodyRowProps: ({ row }) => ({
			sx: {
				backgroundColor: "#fff !important",
			},
			// onClick: () => {
			// 	dispatch(setCurrentCase(row.original));
			// 	navigate("/dashboard/case/details");
			// },
		}),
		onPaginationChange: setPagination,
		onGlobalFilterChange: setSearchQuery,
		onColumnFiltersChange: setColFilter,
		onColumnVisibilityChange: setColumnVisibility,
		onColumnOrderChange: setColumnOrder,
		onColumnSizingChange: setColumnSizing,
		state: {
			pagination,
			searchQuery,
			colFilter,
			columnVisibility,
			columnOrder,
			columnSizing,
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} gap={1}>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <AiOutlineFilePdf color="#fff" />}
					onClick={async () => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter((column) => notReorderedColumns.includes(column));
						const formattedVisibleColumns = reorderedColumns?.map((column) => {
							let columnHeaderName = column;
							if (columnHeaderName === "name") {
								columnHeaderName = t("Name");
							}
							if (columnHeaderName === "type") {
								columnHeaderName = t("Type");
							}
							if (columnHeaderName === "category") {
								columnHeaderName = t("Category");
							}
							if (columnHeaderName === "phone") {
								columnHeaderName = t("PhoneNumber");
							}
							if (columnHeaderName === "email") {
								columnHeaderName = t("Email");
							}
							if (columnHeaderName === "address") {
								columnHeaderName = t("Address");
							}
							if (columnHeaderName === "note") {
								columnHeaderName = t("Note");
							}
							return { title: columnHeaderName, dataKey: columnHeaderName };
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						while (rows.length < totalCount) {
							const skip = rows.length;
							const nextPage = await axiosInstance.get(`contact?skip=${skip}&limit=50`);
							rows.push(...nextPage?.data?.data);
						}
						const dataToConvert = contactFilePreProcessor(rows, visibleColumns, isRtl);
						const reorderedData = dataToConvert.map((obj) => {
							let reorderedObj = {};
							reorderedColumns.forEach((key) => {
								reorderedObj[key] = obj[key];
							});
							return reorderedObj;
						});
						handleConvertToPdf(formattedVisibleColumns, reorderedData, "Contacts Report.pdf", isRtl);
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
						marginLeft: `${isRtl ? "16px" : "0px"} !important`,
					}}
				>
					{t("ExportPdf")}
				</Button>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <SiMicrosoftexcel color="#fff" />}
					onClick={async () => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter((column) => notReorderedColumns.includes(column));
						const formattedVisibleColumns = reorderedColumns?.map((column) => {
							// console.log("column: ", column);
							let columnHeaderName = column;
							if (columnHeaderName === "name") {
								columnHeaderName = t("Name");
							}
							if (columnHeaderName === "type") {
								columnHeaderName = t("Type");
							}
							if (columnHeaderName === "category") {
								columnHeaderName = t("Category");
							}
							if (columnHeaderName === "phone") {
								columnHeaderName = t("PhoneNumber");
							}
							if (columnHeaderName === "email") {
								columnHeaderName = t("Email");
							}
							if (columnHeaderName === "address") {
								columnHeaderName = t("Address");
							}
							if (columnHeaderName === "note") {
								columnHeaderName = t("Note");
							}
							return { title: columnHeaderName, dataKey: columnHeaderName };
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						while (rows.length < totalCount) {
							const skip = rows.length;
							const nextPage = await axiosInstance.get(`contact?skip=${skip}&limit=50`);
							rows.push(...nextPage?.data?.data);
						}
						const dataToConvert = contactFilePreProcessor(rows, visibleColumns, isRtl);
						const formattedData = dataToConvert.map((row) => {
							// console.log(row);
							let formattedRow = {};
							for (let key in row) {
								let newKey = key;
								if (key === "name") {
									newKey = t("Name");
								}
								if (key === "type") {
									newKey = t("Type");
								}
								if (key === "category") {
									newKey = t("Category");
								}
								if (key === "phone") {
									newKey = t("PhoneNumber");
								}
								if (key === "email") {
									newKey = t("Email");
								}
								if (key === "address") {
									newKey = t("Address");
								}
								if (key === "note") {
									newKey = t("Note");
								}
								formattedRow[newKey] = row[key];
							}
							return formattedRow;
						});
						excelExport(formattedData, formattedVisibleColumns, "Contact Report", "Contact Report.xlsx", isRtl);
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
					}}
				>
					{t("ExportExcel")}
				</Button>
				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"caseFile"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"update"}
				/>

				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"caseFile"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"save"}
				/>
				<Button
					onClick={() => setHandleDeleteView(true)}
					disabled={isLoading}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
						padding: 1,
					}}
				>
					{t("DeleteTableView")}
				</Button>
				{colFilter?.length > 0 && (
					<Button
						onClick={() => setColFilter([])}
						disabled={isLoading}
						sx={{
							color: "#fff",
							backgroundColor: "#fc6739",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
							padding: 1,
						}}
					>
						{t("ClearFilter")}
					</Button>
				)}
			</Box>
		),
	});

	return <MaterialReactTable table={table} />;
};

export default ContactTableReport;
