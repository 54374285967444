import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "../../../utils/axios";
import { errorMessage } from "../../../utils/errorCode";

const baseQueryWithAuth = async ({ url, method, body }) => {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const token = userInfo?.accessToken;

	const headers = {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json; charset=utf-8",
	};

	// Conditionally set the Content-Type header if the body is FormData
	if (body instanceof FormData) {
		delete headers["Content-Type"];
	}

	try {
		const result = await axios({
			url: `https://vexpertsystem.ae/api${url}`,
			// url: `http://192.168.55.181:5000/api${url}`,
			method,
			data: body,
			headers: headers,
		});
		return { data: result.data };
	} catch (axiosError) {
		let errCode = axiosError.response?.data.code;
		return {
			error: { data: errorMessage[errCode] },
		};
	}
};

export const apiSlice = createApi({
	baseQuery: baseQueryWithAuth,
	tagTypes: [
		"Auth",
		"User",
		"Users",
		"Contacts",
		"Permissions",
		"Constraints",
		"Cases",
		"Hearings",
		"Tasks",
		"Meetings",
		"Memos",
		"Email",
		"Emails",
		"EmailTemplates",
		"Files",
		"Fees",
		"Storage",
		"Reminders",
		"TableView",
	],
	endpoints: (builder) => ({}),
});
