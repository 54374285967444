import React, { useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCase } from "../../../redux/slices/case";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { useGetConstraintsQuery } from "../../../redux/slices/api/constraints/constraintsApi";
import { useGetAllUsersQuery } from "../../../redux/slices/api/user/userApi";
import { useGetByCriteriaQuery } from "../../../redux/slices/api/contacts/contactApi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { caseFilePreProcessor, excelExport, handleConvertToPdf } from "../../../utils/exportHelper";
import { SiMicrosoftexcel } from "react-icons/si";
import SaveTableView from "../SaveTableView";
import axiosInstance from "../../../utils/axios";

const CommonTable = ({
	data,
	totalCount,
	pagination,
	setPagination,
	pageCount,
	searchQuery,
	setSearchQuery,
	colFilter,
	setColFilter,
	columnVisibility,
	setColumnVisibility,
	columnSizing,
	setColumnSizing,
	columnOrder,
	setColumnOrder,
	setDefaultView,
	selectedTemplate,
	setSelectedTemplate,
	setHandleDeleteView,
}) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const fileTypeConstraint = useGetConstraintsQuery({ reference: "fileType" }, { refetchOnMountOrArgChange: true });
	const caseStageConstraint = useGetConstraintsQuery({ reference: "caseStage" }, { refetchOnMountOrArgChange: true });
	const caseTypeConstraint = useGetConstraintsQuery({ reference: "caseType" }, { refetchOnMountOrArgChange: true });
	const courtConstraint = useGetConstraintsQuery({ reference: "court" }, { refetchOnMountOrArgChange: true });
	const closeReasonConstraint = useGetConstraintsQuery({ reference: "closeReason" }, { refetchOnMountOrArgChange: true });
	const commercialBrokerConstraint = useGetConstraintsQuery({ reference: "commercialBroker" }, { refetchOnMountOrArgChange: true });
	const users = useGetAllUsersQuery({}, { refetchOnMountOrArgChange: true });
	const party = useGetByCriteriaQuery({}, { refetchOnMountOrArgChange: true });

	const columns = useMemo(() => [
		{
			accessorKey: "fileNumber",
			header: t("FileNumber"),
			// size: columnSizing?.fileNumber || 300,

			Cell: ({ renderedCellValue, row }) => (
				<Box
					sx={{
						borderRadius: "5px",
						padding: "5px",
						fontWeight: "bold",
					}}
				>
					<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
						{renderedCellValue}
					</Typography>
				</Box>
			),
		},
		{
			accessorKey: "caseNumber",
			header: t("CaseFileFullName"),
			// size: columnSizing?.caseNumber || 300,

			Cell: ({ renderedCellValue, row }) => {
				const caseYear = row?.original?.caseYear;
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{row?.original?.classification === "consultation" ? "N/A" : renderedCellValue + "/" + caseYear}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.caseType?.id,
			accessorKey: "caseType",
			header: t("CaseType"),
			filterVariant: "select",
			filterSelectOptions: caseTypeConstraint.data?.data?.map((constraint) => ({
				value: constraint?.id,
				label: isRtl ? constraint?.nameAr : constraint?.nameEn ? constraint?.nameEn : constraint?.nameAr,
			})),
			// size: columnSizing?.caseType || 300,

			Cell: ({ renderedCellValue, row }) => {
				const caseType =
					row?.original?.classification === "litigation"
						? isRtl
							? row?.original?.caseType?.nameAr
							: row?.original?.caseType?.nameEn
							? row?.original?.caseType?.nameEn
							: row?.original?.caseType?.nameAr
						: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{caseType}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.caseStage?.id,
			accessorKey: "caseStage",
			header: t("CaseStage"),
			filterVariant: "select",
			filterSelectOptions: caseStageConstraint?.data?.data?.map((constraint) => ({
				value: constraint?.id,
				label: isRtl ? constraint?.nameAr : constraint?.nameEn ? constraint?.nameEn : constraint?.nameAr,
			})),
			// size: columnSizing?.caseStage || 300,

			Cell: ({ renderedCellValue, row }) => {
				const caseStage =
					row?.original?.classification === "litigation"
						? isRtl
							? row?.original?.caseStage?.nameAr
							: row?.original?.caseStage?.nameEn
							? row?.original?.caseStage?.nameEn
							: row?.original?.caseStage?.nameAr
						: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{caseStage}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.parties?.map((party) => party?.party?.id).join(", "),
			header: t("Parties"),
			accessorKey: "parties",
			filterVariant: "autocomplete",
			filterSelectOptions: party?.data?.data?.map((party) => ({
				value: party?.id,
				label: isRtl ? party?.nameAr : party?.nameEn ? party?.nameEn : party?.nameAr,
			})),
			// size: columnSizing?.parties || 300,

			Cell: ({ renderedCellValue, row }) => {
				const parties = row?.original?.parties
					? row?.original?.parties
							?.map((party) => (isRtl ? party?.party?.nameAr : party?.party?.nameEn ? party?.party?.nameEn : party?.party?.nameAr))
							.join(", ")
					: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{parties}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorKey: "status",
			header: t("Status"),
			// size: columnSizing?.status || 300,

			filterVariant: "select",
			filterSelectOptions: [
				{ value: "ongoing", label: t("Ongoing") },
				{ value: "closed", label: t("Closed") },
			],
			Cell: ({ renderedCellValue, row }) => {
				const status = renderedCellValue === "closed" ? "Closed" : "Ongoing";
				return (
					<Box
						sx={{
							borderRadius: 6,
							padding: 0.5,
							backgroundColor: status === "Ongoing" ? "#74c193" : "#fc6b77",
							textAlign: "center",
							textTransform: "capitalize",
							minWidth: "85px",
						}}
					>
						<Typography variant={"subtitle2"} fontWeight={500} color={"#fff"}>
							{t(status)}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorKey: "classification",
			header: t("Classification"),
			filterVariant: "select",

			filterSelectOptions: [
				{ value: "litigation", label: t("Litigation") },
				{ value: "consultation", label: t("Consultation") },
			],
			// size: columnSizing?.classification || 300,
			Cell: ({ renderedCellValue }) => (
				<Box
					sx={{
						borderRadius: "5px",
						padding: "5px",
						fontWeight: "bold",
					}}
				>
					<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
						{t(renderedCellValue)}
					</Typography>
				</Box>
			),
		},
		{
			accessorFn: (row) => row?.fileType?.id,
			accessorKey: "fileType",
			header: t("FileType"),
			filterVariant: "select",
			// size: columnSizing?.fileType || 300,

			filterSelectOptions: fileTypeConstraint.data?.data?.map((constraint) => ({
				value: constraint?.id,
				label: isRtl ? constraint?.nameAr : constraint?.nameEn ? constraint?.nameEn : constraint?.nameAr,
			})),
			Cell: ({ renderedCellValue, row }) => {
				const fileType = isRtl
					? row?.original?.fileType?.nameAr
					: row?.original?.fileType?.nameEn
					? row?.original?.fileType?.nameEn
					: row?.original?.fileType?.nameAr;
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={600} color={"#164c68"}>
							{fileType}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.court?.id,
			accessorKey: "court",
			header: t("Court"),
			filterVariant: "select",

			filterSelectOptions: courtConstraint.data?.data?.map((constraint) => ({
				value: constraint?.id,
				label: isRtl ? constraint?.nameAr : constraint?.nameEn ? constraint?.nameEn : constraint?.nameAr,
			})),
			// size: columnSizing?.court || 300,
			Cell: ({ renderedCellValue, row }) => {
				const court =
					row?.original?.classification === "litigation"
						? isRtl
							? row?.original?.court?.nameAr
							: row?.original?.court?.nameEn
							? row?.original?.court?.nameEn
							: row?.original?.court?.nameAr
						: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{court}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => (row?.expertAssignmentDate ? moment(row?.expertAssignmentDate).format("DD/MM/YYYY") : "N/A"),
			accessorKey: "expertAssignmentDate",
			header: t("ExpertAssignmentDate"),
			// size: columnSizing?.expertAssignmentDate || 400,

			enableColumnFilter: false,
			Cell: ({ renderedCellValue }) => (
				<Box
					sx={{
						borderRadius: "5px",
						padding: "5px",
						fontWeight: "bold",
					}}
				>
					<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
						{renderedCellValue}
					</Typography>
				</Box>
			),
		},
		{
			accessorFn: (row) => new Date(row?.openDate).toLocaleDateString(),
			accessorKey: "openDate",
			header: t("OpenDate"),
			filterVariant: "date-range",
			// size: columnSizing?.openDate || 300,

			Cell: ({ renderedCellValue, row }) => {
				const openDate = row?.original?.openDate ? moment(row?.original?.openDate).format("YYYY/MM/DD") : "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{openDate}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => (row?.lastHearing ? moment(row?.lastHearing).format("DD/MM/YYYY") : "N/A"),
			accessorKey: "lastHearing",
			header: t("LastHearingDate"),
			// size: columnSizing?.lastHearing || 300,

			Cell: ({ renderedCellValue }) => (
				<Box
					sx={{
						borderRadius: "5px",
						padding: "5px",
						fontWeight: "bold",
					}}
				>
					<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
						{renderedCellValue}
					</Typography>
				</Box>
			),
		},
		{
			accessorFn: (row) => (row?.lastMeeting ? moment(row?.lastMeeting).format("DD/MM/YYYY") : "N/A"),
			accessorKey: "lastMeeting",
			header: t("LastMeetingDate"),
			// size: columnSizing?.lastMeeting || 300,

			enableColumnFilter: false,
			Cell: ({ renderedCellValue }) => (
				<Box
					sx={{
						borderRadius: "5px",
						padding: "5px",
						fontWeight: "bold",
					}}
				>
					<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
						{renderedCellValue}
					</Typography>
				</Box>
			),
		},
		{
			accessorFn: (row) => new Date(row?.closeDate).toLocaleDateString(),
			accessorKey: "closeDate",
			header: t("CloseDate"),
			// size: columnSizing?.closeDate || 300,

			filterVariant: "date-range",
			Cell: ({ renderedCellValue, row }) => {
				const closeDate = row?.original?.closeDate ? moment(row?.original?.closeDate).format("YYYY/MM/DD") : "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{closeDate}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorKey: "totalFee",
			header: t("TotalFees"),
			// size: columnSizing?.totalFee || 300,

			enableColumnFilter: false,
			Cell: ({ renderedCellValue }) => {
				const amount = renderedCellValue;
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							AED {amount.toLocaleString()}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => (row?.note ? row?.note : "N/A"),
			header: t("Note"),
			accessorKey: "note",
			// size: columnSizing?.note || 600,

			Cell: ({ renderedCellValue }) => (
				<Box
					sx={{
						borderRadius: "5px",
						padding: "5px",
						fontWeight: "bold",
					}}
				>
					<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
						{renderedCellValue.length > 50 ? `${renderedCellValue.substring(0, 50)}...` : renderedCellValue}
					</Typography>
				</Box>
			),
		},
		{
			accessorFn: (row) => (row?.subject ? row?.subject : "N/A"),
			header: t("Subject"),
			accessorKey: "subject",
			// size: columnSizing?.subject || 600,

			Cell: ({ renderedCellValue }) => (
				<Box
					sx={{
						borderRadius: "5px",
						padding: "5px",
						fontWeight: "bold",
					}}
				>
					<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
						{renderedCellValue.length > 50 ? `${renderedCellValue.substring(0, 50)}...` : renderedCellValue}
					</Typography>
				</Box>
			),
		},
		{
			accessorFn: (row) => row?.userByPosition?.Expert?.map((user) => user?.id).join(", "),
			header: t("Experts"),
			accessorKey: "Expert",
			filterVariant: "autocomplete",
			filterSelectOptions: users?.data?.data?.map((user) => ({
				value: user?.id,
				label: user?.fullName,
			})),
			// size: columnSizing?.Expert || 400,

			Cell: ({ renderedCellValue, row }) => {
				const experts = row?.original?.userByPosition?.Expert ? row?.original?.userByPosition?.Expert?.map((user) => user?.fullName).join(", ") : "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{experts}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.userByPosition?.Admin?.map((user) => user?.id).join(", "),
			header: t("Admins"),
			accessorKey: "Admin",
			filterVariant: "autocomplete",
			filterSelectOptions: users?.data?.data?.map((user) => ({
				value: user?.id,
				label: user?.fullName,
			})),
			// size: columnSizing?.Admin || 400,

			Cell: ({ renderedCellValue, row }) => {
				const admins = row?.original?.userByPosition?.Admin ? row?.original?.userByPosition?.Admin?.map((user) => user?.fullName).join(", ") : "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{admins}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.userByPosition?.["Expert Assistant"]?.map((user) => user?.id).join(", "),
			header: t("ExpertAssistants"),
			accessorKey: "Expert Assistant",
			filterVariant: "autocomplete",
			filterSelectOptions: users?.data?.data?.map((user) => ({
				value: user?.id,
				label: user?.fullName,
			})),
			// size: columnSizing?.["Expert Assistant"] || 400,

			Cell: ({ renderedCellValue, row }) => {
				const expertAssistants = row?.original?.userByPosition?.["Expert Assistant"]
					? row?.original?.userByPosition?.["Expert Assistant"]?.map((user) => user?.fullName).join(", ")
					: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{expertAssistants}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.userByPosition?.Auditor?.map((user) => user?.id).join(", "),
			header: t("Auditors"),
			accessorKey: "Auditor",
			filterVariant: "autocomplete",
			filterSelectOptions: users?.data?.data?.map((user) => ({
				value: user?.id,
				label: user?.fullName,
			})),
			// size: columnSizing?.Auditor || 400,

			Cell: ({ renderedCellValue, row }) => {
				const auditors = row?.original?.userByPosition?.Auditor
					? row?.original?.userByPosition?.Auditor?.map((user) => user?.fullName).join(", ")
					: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{auditors}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.userByPosition?.Translator?.map((user) => user?.id).join(", "),
			header: t("Translators"),
			accessorKey: "Translator",
			filterVariant: "autocomplete",
			filterSelectOptions: users?.data?.data?.map((user) => ({
				value: user?.id,
				label: user?.fullName,
			})),
			// size: columnSizing?.Translator || 400,

			Cell: ({ renderedCellValue, row }) => {
				const translators = row?.original?.userByPosition?.Translator
					? row?.original?.userByPosition?.Translator?.map((user) => user?.fullName).join(", ")
					: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{translators}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.userByPosition?.Accountant?.map((user) => user?.id).join(", "),
			header: t("Accountants"),
			accessorKey: "Accountant",
			filterVariant: "autocomplete",
			filterSelectOptions: users?.data?.data?.map((user) => ({
				value: user?.id,
				label: user?.fullName,
			})),
			// size: columnSizing?.Accountant || 400,

			Cell: ({ renderedCellValue, row }) => {
				const accountants = row?.original?.userByPosition?.Accountant
					? row?.original?.userByPosition?.Accountant?.map((user) => user?.fullName).join(", ")
					: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{accountants}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.userByPosition?.Secretary?.map((user) => user?.id).join(", "),
			header: t("Secretaries"),
			accessorKey: "Secretary",
			filterVariant: "autocomplete",
			filterSelectOptions: users?.data?.data?.map((user) => ({
				value: user?.id,
				label: user?.fullName,
			})),
			// size: columnSizing?.Secretary || 400,

			Cell: ({ renderedCellValue, row }) => {
				const secretaries = row?.original?.userByPosition?.Secretary
					? row?.original?.userByPosition?.Secretary?.map((user) => user?.fullName).join(", ")
					: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{secretaries}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.userByPosition?.Administrative?.map((user) => user?.id).join(", "),
			header: t("Administrative"),
			accessorKey: "Administrative",
			filterVariant: "autocomplete",
			filterSelectOptions: users?.data?.data?.map((user) => ({
				value: user?.id,
				label: user?.fullName,
			})),
			// size: columnSizing?.Administrative || 400,

			Cell: ({ renderedCellValue, row }) => {
				const administrative = row?.original?.userByPosition?.Administrative
					? row?.original?.userByPosition?.Administrative?.map((user) => user?.fullName).join(", ")
					: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{administrative}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.closeReason?.id,
			header: t("CloseReason"),
			id: "closeReason",
			// size: columnSizing?.closeReason || 350,

			filterVariant: "select",
			filterSelectOptions: closeReasonConstraint.data?.data?.map((constraint) => ({
				value: constraint.id,
				label: isRtl ? constraint.nameAr : constraint.nameEn ? constraint.nameEn : constraint.nameAr,
			})),
			Cell: ({ renderedCellValue, row }) => {
				const closeReason =
					row?.original?.status === "closed"
						? isRtl
							? row?.original?.closeReason?.nameAr
							: row?.original?.closeReason?.nameEn
							? row?.original?.closeReason?.nameEn
							: row?.original?.closeReason?.nameAr
						: "N/A";
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{closeReason}
						</Typography>
					</Box>
				);
			},
		},
		{
			accessorFn: (row) => row?.commercialBroker?.id,
			header: t("CommercialBroker"),
			id: "commercialBroker",
			// size: columnSizing?.commercialBroker || 350,

			filterVariant: "select",
			filterSelectOptions: commercialBrokerConstraint.data?.data?.map((constraint) => ({
				value: constraint.id,
				label: isRtl ? constraint.nameAr : constraint.nameEn ? constraint.nameEn : constraint.nameAr,
			})),
			Cell: ({ renderedCellValue, row }) => {
				const commercialBroker = isRtl
					? row?.original?.commercialBroker?.nameAr
					: row?.original?.commercialBroker?.nameEn
					? row?.original?.commercialBroker?.nameEn
					: row?.original?.commercialBroker?.nameAr;
				return (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{commercialBroker ? commercialBroker : "N/A"}
						</Typography>
					</Box>
				);
			},
		},
	]);

	const table = useMaterialReactTable({
		columns,
		data,
		columnFilterDisplayMode: "popover",
		muiTableHeadCellProps: {
			align: "center",
			sx: {
				color: "#164c68",
				"& .css-d0cm6k .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionAsc": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionDesc": {
					color: "#164c68 !important",
				},
			},
		},
		muiTableBodyCellProps: {
			align: "center",
		},
		enableStickyHeader: true,
		enableColumnResizing: true,
		enableColumnDragging: true,
		enableColumnOrdering: true,
		enableFacetedValues: true,
		layoutMode: "semantic",
		columnResizeMode: "onEnd",
		initialState: { density: "compact" },
		enableFullScreenToggle: false,
		enableRowActions: false,
		paginationDisplayMode: "pages",
		positionToolbarAlertBanner: "bottom",
		muiSearchTextFieldProps: {
			// size: "small",
			variant: "outlined",
		},
		manualPagination: true,
		manualFiltering: true,
		muiPaginationProps: {
			color: "primary",
			rowsPerPageOptions: [10, 20, 30, 50, 100],
			shape: "rounded",
			variant: "outlined",
		},
		rowCount: pagination.pageSize,
		pageCount: pageCount,
		muiTableBodyRowProps: ({ row }) => ({
			sx: {
				backgroundColor: "#fff !important",
			},
			onClick: () => {
				dispatch(setCurrentCase(row.original));
				navigate("/dashboard/case/details");
			},
		}),
		onPaginationChange: setPagination,
		onGlobalFilterChange: setSearchQuery,
		onColumnFiltersChange: setColFilter,
		onColumnVisibilityChange: setColumnVisibility,
		onColumnOrderChange: setColumnOrder,
		onColumnSizingChange: setColumnSizing,
		state: {
			pagination,
			searchQuery,
			colFilter,
			columnVisibility,
			columnOrder,
			columnSizing,
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} gap={1}>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <AiOutlineFilePdf color="#fff" />}
					onClick={async () => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter((column) => notReorderedColumns.includes(column));
						const formattedVisibleColumns = reorderedColumns?.map((column) => {
							let columnHeaderName = column;
							if (columnHeaderName === "fileNumber") {
								columnHeaderName = t("FileNumber");
							}
							if (columnHeaderName === "status") {
								columnHeaderName = t("Status");
							}
							if (columnHeaderName === "classification") {
								columnHeaderName = t("Classification");
							}
							if (columnHeaderName === "caseNumber") {
								columnHeaderName = t("CaseFileFullName");
							}
							if (columnHeaderName === "caseType") {
								columnHeaderName = t("CaseType");
							}
							if (columnHeaderName === "caseStage") {
								columnHeaderName = t("CaseStage");
							}
							if (columnHeaderName === "parties") {
								columnHeaderName = t("Parties");
							}
							if (columnHeaderName === "fileType") {
								columnHeaderName = t("FileType");
							}
							if (columnHeaderName === "court") {
								columnHeaderName = t("Court");
							}
							if (columnHeaderName === "expertAssignmentDate") {
								columnHeaderName = t("ExpertAssignmentDate");
							}
							if (columnHeaderName === "openDate") {
								columnHeaderName = t("OpenDate");
							}
							if (columnHeaderName === "lastHearing") {
								columnHeaderName = t("LastHearingDate");
							}
							if (columnHeaderName === "lastMeeting") {
								columnHeaderName = t("LastMeetingDate");
							}
							if (columnHeaderName === "closeDate") {
								columnHeaderName = t("CloseDate");
							}
							if (columnHeaderName === "closeReason") {
								columnHeaderName = t("CloseReason");
							}
							if (columnHeaderName === "totalFee") {
								columnHeaderName = t("TotalFees");
							}
							if (columnHeaderName === "note") {
								columnHeaderName = t("Note");
							}
							if (columnHeaderName === "subject") {
								columnHeaderName = t("Subject");
							}
							if (columnHeaderName === "Expert") {
								columnHeaderName = t("Experts");
							}
							if (columnHeaderName === "Admin") {
								columnHeaderName = t("Admins");
							}
							if (columnHeaderName === "Expert Assistant") {
								columnHeaderName = t("ExpertAssistants");
							}
							if (columnHeaderName === "Auditor") {
								columnHeaderName = t("Auditors");
							}
							if (columnHeaderName === "Translator") {
								columnHeaderName = t("Translators");
							}
							if (columnHeaderName === "Accountant") {
								columnHeaderName = t("Accountants");
							}
							if (columnHeaderName === "Secretary") {
								columnHeaderName = t("Secretaries");
							}
							if (columnHeaderName === "Administrative") {
								columnHeaderName = t("Administrative");
							}
							if (columnHeaderName === "commercialBroker") {
								columnHeaderName = t("CommercialBroker");
							}
							return { title: columnHeaderName, dataKey: columnHeaderName };
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						while (rows.length < totalCount) {
							const skip = rows.length;
							const nextPage = await axiosInstance.get(`case-file?skip=${skip}&limit=50`);
							rows.push(...nextPage?.data?.data);
						}
						const dataToConvert = caseFilePreProcessor(rows, visibleColumns, isRtl);
						const reorderedData = dataToConvert.map((obj) => {
							let reorderedObj = {};
							reorderedColumns.forEach((key) => {
								reorderedObj[key] = obj[key];
							});
							return reorderedObj;
						});
						handleConvertToPdf(formattedVisibleColumns, reorderedData, "Case File Report.pdf", isRtl);
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
						marginLeft: `${isRtl ? "16px" : "0px"} !important`,
					}}
				>
					{t("ExportPdf")}
				</Button>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <SiMicrosoftexcel color="#fff" />}
					onClick={async () => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter((column) => notReorderedColumns.includes(column));
						const formattedVisibleColumns = reorderedColumns?.map((column) => {
							let columnHeaderName = column;
							if (columnHeaderName === "fileNumber") {
								columnHeaderName = t("FileNumber");
							}
							if (columnHeaderName === "status") {
								columnHeaderName = t("Status");
							}
							if (columnHeaderName === "classification") {
								columnHeaderName = t("Classification");
							}
							if (columnHeaderName === "caseNumber") {
								columnHeaderName = t("CaseFileFullName");
							}
							if (columnHeaderName === "caseType") {
								columnHeaderName = t("CaseType");
							}
							if (columnHeaderName === "caseStage") {
								columnHeaderName = t("CaseStage");
							}
							if (columnHeaderName === "parties") {
								columnHeaderName = t("Parties");
							}
							if (columnHeaderName === "fileType") {
								columnHeaderName = t("FileType");
							}
							if (columnHeaderName === "court") {
								columnHeaderName = t("Court");
							}
							if (columnHeaderName === "expertAssignmentDate") {
								columnHeaderName = t("ExpertAssignmentDate");
							}
							if (columnHeaderName === "openDate") {
								columnHeaderName = t("OpenDate");
							}
							if (columnHeaderName === "lastHearing") {
								columnHeaderName = t("LastHearingDate");
							}
							if (columnHeaderName === "lastMeeting") {
								columnHeaderName = t("LastMeetingDate");
							}
							if (columnHeaderName === "closeDate") {
								columnHeaderName = t("CloseDate");
							}
							if (columnHeaderName === "closeReason") {
								columnHeaderName = t("CloseReason");
							}
							if (columnHeaderName === "totalFee") {
								columnHeaderName = t("TotalFees");
							}
							if (columnHeaderName === "note") {
								columnHeaderName = t("Note");
							}
							if (columnHeaderName === "subject") {
								columnHeaderName = t("Subject");
							}
							if (columnHeaderName === "Expert") {
								columnHeaderName = t("Experts");
							}
							if (columnHeaderName === "Admin") {
								columnHeaderName = t("Admins");
							}
							if (columnHeaderName === "Expert Assistant") {
								columnHeaderName = t("ExpertAssistants");
							}
							if (columnHeaderName === "Auditor") {
								columnHeaderName = t("Auditors");
							}
							if (columnHeaderName === "Translator") {
								columnHeaderName = t("Translators");
							}
							if (columnHeaderName === "Accountant") {
								columnHeaderName = t("Accountants");
							}
							if (columnHeaderName === "Secretary") {
								columnHeaderName = t("Secretaries");
							}
							if (columnHeaderName === "Administrative") {
								columnHeaderName = t("Administrative");
							}
							if (columnHeaderName === "commercialBroker") {
								columnHeaderName = t("CommercialBroker");
							}
							return columnHeaderName;
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						while (rows.length < totalCount) {
							const skip = rows.length;
							const nextPage = await axiosInstance.get(`case-file?skip=${skip}&limit=50`);
							rows.push(...nextPage?.data?.data);
						}
						const dataToConvert = caseFilePreProcessor(rows, visibleColumns, isRtl);
						const formattedData = dataToConvert.map((row) => {
							let formattedRow = {};
							for (let key in row) {
								let newKey = key;
								if (key === "fileNumber") {
									newKey = t("FileNumber");
								}
								if (key === "caseNumber") {
									newKey = t("CaseFileFullName");
								}
								if (key === "caseStage") {
									newKey = t("CaseStage");
								}
								if (key === "fileType") {
									newKey = t("FileType");
								}
								if (key === "status") {
									newKey = t("Status");
								}
								if (key === "caseType") {
									newKey = t("CaseType");
								}
								if (key === "court") {
									newKey = t("Court");
								}
								if (key === "parties") {
									newKey = t("Parties");
								}
								if (key === "Expert") {
									newKey = t("Experts");
								}
								if (key === "Expert Assistant") {
									newKey = t("ExpertAssistants");
								}
								if (key === "classification") {
									newKey = t("Classification");
								}
								if (key === "note") {
									newKey = t("Note");
								}
								if (key === "subject") {
									newKey = t("Subject");
								}
								if (key === "Admin") {
									newKey = t("Admins");
								}
								if (key === "Auditor") {
									newKey = t("Auditors");
								}
								if (key === "Translator") {
									newKey = t("Translators");
								}
								if (key === "Accountant") {
									newKey = t("Accountants");
								}
								if (key === "Secretary") {
									newKey = t("Secretaries");
								}
								if (key === "Administrative") {
									newKey = t("Administrative");
								}
								if (key === "expertAssignmentDate") {
									newKey = t("ExpertAssignmentDate");
								}
								if (key === "openDate") {
									newKey = t("OpenDate");
								}
								if (key === "lastHearing") {
									newKey = t("LastHearingDate");
								}
								if (key === "lastMeeting") {
									newKey = t("LastMeetingDate");
								}
								if (key === "closeDate") {
									newKey = t("CloseDate");
								}
								if (key === "closeReason") {
									newKey = t("CloseReason");
								}
								if (key === "totalFee") {
									newKey = t("TotalFees");
								}
								if (key === "commercialBroker") {
									key = t("CommercialBroker");
								}
								formattedRow[newKey] = row[key];
							}
							return formattedRow;
						});
						excelExport(formattedData, formattedVisibleColumns, "Case File Report", "Case File Report.xlsx", isRtl);
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
					}}
				>
					{t("ExportExcel")}
				</Button>
				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"caseFile"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"update"}
				/>

				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"caseFile"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"save"}
				/>
				<Button
					onClick={() => setHandleDeleteView(true)}
					disabled={isLoading}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
						padding: 1,
					}}
				>
					{t("DeleteTableView")}
				</Button>
				{colFilter?.length > 0 && (
					<Button
						onClick={() => setColFilter([])}
						disabled={isLoading}
						sx={{
							color: "#fff",
							backgroundColor: "#fc6739",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
							padding: 1,
						}}
					>
						{t("ClearFilter")}
					</Button>
				)}
			</Box>
		),
	});

	return <MaterialReactTable table={table} />;
};

export default CommonTable;
