import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SectionHeader from "../../Common/SectionHeader";
import { GiGavel } from "react-icons/gi";
import CommonTable from "../../Common/Tables";
import { useTranslation } from "react-i18next";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";
import { setCurrentCase, setIsEditingCase } from "../../../redux/slices/case";
import DeleteCaseDialog from "../CaseDetails/SecondSection/DeleteCaseDialog";
import { images } from "../../Assets";
import { IoClose } from "react-icons/io5";
import ScrollableContainer from "../../Common/ScrollableContainer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useGetTableViewQuery, useUpdateTableViewMutation } from "../../../redux/slices/api/tableView/tableViewApi";
import { LoadingButton } from "@mui/lab";
import { setSnackbar } from "../../../redux/slices/app";
import ConfirmDeleteTableView from "../../Common/ConfirmDeleteTableView";

const Cases = () => {
	const { t } = useTranslation();

	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [caseClassification, setCaseClassification] = useState("All");
	const [colFilter, setColFilter] = useState([]);

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 50,
	});

	const [totalCount, setTotalCount] = useState(0);

	const [searchQuery, setSearchQuery] = useState("");

	const [isColumnFiltering, setIsColumnFiltering] = useState(false);

	const [columnVisibility, setColumnVisibility] = useState({});
	const [columnSizing, setColumnSizing] = useState({});
	const [columnOrder, setColumnOrder] = useState([]);
	const [defaultTemplate, setDefaultTemplate] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [handleDeleteView, setHandleDeleteView] = useState(false);

	const colFilterObject = colFilter.reduce((acc, { id, value }) => {
		if (id === "openDate") {
			id = "openDate[gte]";
			let fromDate;
			let toDate;
			if (value[0]) fromDate = value[0].format("YYYY-MM-DD");
			if (value[1]) toDate = value[1].format("YYYY-MM-DD");
			value = `${fromDate}&openDate[lte]=${toDate}`;
		}
		if (id === "closeDate") {
			id = "closeDate[gte]";
			let fromDate;
			let toDate;
			if (value[0]) fromDate = value[0].format("YYYY-MM-DD");
			if (value[1]) toDate = value[1].format("YYYY-MM-DD");
			value = `${fromDate}&closeDate[lte]=${toDate}`;
		}
		if (id === "users") id = "user";
		if (id === "parties") id = "party";

		acc[id] = value;
		return acc;
	}, {});

	const caseQuery = {
		...(caseClassification !== "All" && { classification: caseClassification }),
		...(searchQuery && { search: searchQuery }),
		...(colFilter.length > 0 && { ...colFilterObject }),
		skip: pagination.pageIndex * pagination.pageSize,
		limit: pagination.pageSize,
	};

	const { data, isLoading, isError } = useGetCasesQuery(caseQuery, { refetchOnMountOrArgChange: true });

	const [handleOpenDeleteDialog, setHandleOpenDeleteDialog] = useState(false);

	const [rows, setRows] = useState(data?.data || []);

	useEffect(() => {
		if (isError) return;

		if (data && !isLoading && !isError) {
			setRows(data?.data);
			setTotalCount(data?.totalRecords);
		}
	}, [data, pagination.pageIndex, pagination.pageSize, caseClassification, searchQuery, colFilter]);

	const tableView = useGetTableViewQuery({ type: "caseFile" }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (tableView.data?.data?.caseFile) {
			const defaultView = tableView.data?.data?.caseFile?.find((view) => view.default === true);
			if (defaultView) {
				setDefaultTemplate(defaultView);
				setSelectedTemplate(defaultView);
				setColumnVisibility(JSON.parse(defaultView?.column));
				setColumnOrder(JSON.parse(defaultView?.columnOrder));
			} else {
				const defaultTemp = tableView.data?.data?.caseFile[0];
				setSelectedTemplate(defaultTemp);
				setColumnVisibility(JSON.parse(defaultTemp?.column));
				setColumnOrder(JSON.parse(defaultTemp?.columnOrder));
			}
			setTemplates(tableView.data?.data?.caseFile);
		} else {
			setSelectedTemplate(null);
			setColumnVisibility({});
			setColumnOrder([]);
		}
	}, [tableView.data, templates]);

	const [updateTableView] = useUpdateTableViewMutation();

	const handleSetAsDefault = async (e) => {
		e.preventDefault();
		setLoading(true);
		const data = {
			default: true,
		};
		try {
			await updateTableView({ templateId: selectedTemplate?.id, data: data }).unwrap();
			setDefaultTemplate(selectedTemplate);
			setSelectedTemplate(selectedTemplate);
			setLoading(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Box
			width={"100%"}
			height={"max-content"}
			p={2}
			borderRadius={2}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
		>
			<SectionHeader icon={<GiGavel color="#164c68" size={18} />} title={`${t("My")} ${t("Cases")}`} section={"cases"} type={"dialog"} />
			<Box height={"max-content"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
				<Box
					sx={{
						width: "100%",
						height: "100%",
					}}
				>
					<Stack width={"100%"} my={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1}>
						<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<Button
								size="small"
								startIcon={!isRtl && <MdOutlineAdd color="#fff" />}
								onClick={() => {
									dispatch(setCurrentCase(null));
									dispatch(setIsEditingCase(false));
									navigate("/dashboard/case/create");
								}}
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
									padding: 1,
									"&:hover": {
										bgcolor: "#00a3b1",
									},
									transition: "all 0.5s ease",
								}}
							>
								{t("CreateNew")}
							</Button>
							<FormControl sx={{ m: 1, minWidth: 100, mr: isRtl ? "8px !important" : 0 }} size="small">
								<Select
									id="select-classification"
									value={caseClassification}
									onChange={(e) => setCaseClassification(e.target.value)}
									sx={{ color: "#164c68" }}
									displayEmpty
								>
									<MenuItem value={"All"}>{t("All")}</MenuItem>
									<MenuItem value={"litigation"}>{t("Litigation")}</MenuItem>
									<MenuItem value={"consultation"}>{t("Consultation")}</MenuItem>
								</Select>
							</FormControl>

							<Typography variant={"h5"} color={"#164c68"}>
								{t("SelectedTemplate")}:
							</Typography>

							{templates?.length > 0 ? (
								<FormControl sx={{ m: 1, minWidth: 100, mr: isRtl ? "8px !important" : 0 }} size="small">
									<Select
										id="select-template"
										value={selectedTemplate}
										onChange={(e) => {
											setSelectedTemplate(e.target.value);
											setColumnVisibility(JSON.parse(e?.target?.value?.column));
											setColumnOrder(JSON.parse(e?.target?.value?.columnOrder));
										}}
										sx={{ color: "#164c68" }}
										displayEmpty
									>
										{defaultTemplate && (
											<MenuItem value={defaultTemplate}>
												{defaultTemplate?.name} ({t("Default")})
											</MenuItem>
										)}
										{templates
											?.filter((template) => defaultTemplate?.id !== template.id)
											.map((template) => (
												<MenuItem key={template.id} value={template}>
													{template.name}
												</MenuItem>
											))}
									</Select>
								</FormControl>
							) : (
								<Typography variant={"h5"} color={"#164c68"}>
									{t("NoTableViewFound")}
								</Typography>
							)}
							{selectedTemplate?.id !== defaultTemplate?.id && selectedTemplate && (
								<LoadingButton
									loading={isLoading}
									variant="contained"
									size="small"
									onClick={handleSetAsDefault}
									sx={{
										bgcolor: "#164c68",
										color: "#fff",
										padding: 1,
										"&:hover": {
											bgcolor: "#00a3b1",
										},
										transition: "all 0.5s ease",
									}}
								>
									{t("SetAsDefault")}
								</LoadingButton>
							)}
						</Stack>
					</Stack>
					{isLoading && !isError ? (
						<CircularProgress />
					) : rows ? (
						<ScrollableContainer>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<CommonTable
									data={rows}
									totalCount={totalCount}
									pagination={pagination}
									setPagination={setPagination}
									pageCount={Math.ceil(totalCount / pagination.pageSize)}
									deleteCase={() => setHandleOpenDeleteDialog(true)}
									searchQuery={searchQuery}
									setSearchQuery={setSearchQuery}
									colFilter={colFilter}
									setColFilter={setColFilter}
									columnVisibility={columnVisibility}
									setColumnVisibility={setColumnVisibility}
									columnSizing={columnSizing}
									setColumnSizing={setColumnSizing}
									columnOrder={columnOrder}
									setColumnOrder={setColumnOrder}
									setDefaultView={setDefaultTemplate}
									selectedTemplate={selectedTemplate}
									setSelectedTemplate={setSelectedTemplate}
									templates={templates}
									setHandleDeleteView={setHandleDeleteView}
								/>
							</LocalizationProvider>
						</ScrollableContainer>
					) : (
						<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={10}>
							<img src={images.notFound} alt="Not Found" width={200} height={200} />
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
								{t("NoCaseFileFound")}
							</Typography>
							<Button
								size="small"
								endIcon={!isRtl && <IoClose color="red" />}
								onClick={() => {
									setSearchQuery("");
									setColFilter([]);
									setIsColumnFiltering(false);
								}}
								sx={{
									bgcolor: "#fff",
									color: "#164c68",
									padding: 1,
									border: "1px solid #164c68",
								}}
							>
								{t("ClearSearch")}
							</Button>
						</Box>
					)}
				</Box>
			</Box>
			{handleOpenDeleteDialog && <DeleteCaseDialog open={handleOpenDeleteDialog} handleClose={() => setHandleOpenDeleteDialog(false)} />}
			{handleDeleteView && (
				<ConfirmDeleteTableView
					open={handleDeleteView}
					handleClose={() => setHandleDeleteView(false)}
					templateId={selectedTemplate?.id}
					template={selectedTemplate}
					setTemplates={setTemplates}
					setSelectedTemplate={() => setSelectedTemplate(null)}
					type={"caseFile"}
					setColumnVisibility={setColumnVisibility}
					setColumnOrder={setColumnOrder}
					setDefaultView={setDefaultTemplate}
				/>
			)}
		</Box>
	);
};

export default Cases;
