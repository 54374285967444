import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Stack, Tab, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { CiCalendarDate, CiViewList } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentMeeting, setIsCreatingMeeting, setIsEditingMeeting } from "../../../../redux/slices/meetings";
import SectionHeader from "../../../Common/SectionHeader";
import { useGetAllMeetingsQuery } from "../../../../redux/slices/api/meetings/meetingsApi";
import MeetingTable from "./MeetingTable";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import MyCalendar from "../../../Common/Calendar";
import { useTranslation } from "react-i18next";
import { MdOutlineAdd } from "react-icons/md";
import ConfirmDeleteDialog from "../../../Meetings/Details/ConfirmDeleteDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ScrollableContainer from "../../../Common/ScrollableContainer";
import { useGetTableViewQuery, useUpdateTableViewMutation } from "../../../../redux/slices/api/tableView/tableViewApi";
import { setSnackbar } from "../../../../redux/slices/app";
import ConfirmDeleteTableView from "../../../Common/ConfirmDeleteTableView";

const MeetingSection = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const currentCase = useSelector((state) => state.case.currentCase);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [tabValue, setTabValue] = useState("list");

	const [viewingEvent, setViewingEvent] = useState(false);
	const [deleteMeeting, setDeleteMeeting] = useState(false);

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 50,
	});

	const [searchQuery, setSearchQuery] = useState("");
	const [colFilter, setColFilter] = useState([]);
	const [columnSizing, setColumnSizing] = useState({});

	const colFilterObject = colFilter?.reduce((acc, { id, value }) => {
		if (id === "caseFile.caseNumber") id = "caseFile";
		if (id === "users") id = "user";
		if (id === "contacts") id = "contact";

		if (id === "date") {
			id = "date[gte]";
			let fromDate;
			let toDate;
			if (value[0]) fromDate = value[0].format("YYYY-MM-DD");
			if (value[1]) toDate = value[1].format("YYYY-MM-DD");
			value = `${fromDate}&date[lte]=${toDate}`;
		}

		acc[id] = value;
		return acc;
	}, {});

	const meetingQuery = {
		...(searchQuery?.length > 2 && { search: searchQuery }),
		...(colFilter?.length > 0 && { ...colFilterObject }),
		caseFile: currentCase.id,
		skip: pagination.pageIndex * pagination.pageSize,
		limit: pagination.pageSize,
	};

	const { data, isLoading, isError } = useGetAllMeetingsQuery(meetingQuery, { refetchOnMountOrArgChange: true });

	const [totalCount, setTotalCount] = useState(data?.totalRecords || 0);

	useEffect(() => {
		if (isError) return;

		if (data) {
			setRows(data?.data);
			const formattedData = data?.data.map((meeting) => {
				return {
					...meeting,
					title: meeting.subject ? meeting.subject : "Untitled Meeting",
					start: new Date(meeting.date),
					end: meeting.endDate ? new Date(meeting.endDate) : new Date(meeting.date),
					allDay: false,
				};
			});
			setCalendarData(formattedData);
			setTotalCount(data?.totalRecords);
		}
	}, [data, isLoading, isError, tabValue, pagination.pageIndex, pagination.pageSize, searchQuery, colFilter]);

	const [rows, setRows] = useState(data?.data || []);
	const [calendarData, setCalendarData] = useState([]);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		if (!isMounted) {
			setIsMounted(true);
		} else {
			dispatch(setCurrentMeeting(null));
		}
	}, [isMounted]);

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	const tableView = useGetTableViewQuery({ type: "meeting" }, { refetchOnMountOrArgChange: true });

	const [columnVisibility, setColumnVisibility] = useState({});
	const [columnOrder, setColumnOrder] = useState([]);
	const [defaultTemplate, setDefaultTemplate] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [handleDeleteView, setHandleDeleteView] = useState(false);

	useEffect(() => {
		if (tableView.data?.data?.meeting) {
			const defaultView = tableView.data?.data?.meeting?.find((view) => view.default === true);
			if (defaultView) {
				setDefaultTemplate(defaultView);
				setSelectedTemplate(defaultView);
				setColumnVisibility(JSON.parse(defaultView?.column));
				setColumnOrder(JSON.parse(defaultView?.columnOrder));
			} else {
				const defaultTemp = tableView.data?.data?.meeting[0];
				setSelectedTemplate(defaultTemp);
				setColumnVisibility(JSON.parse(defaultTemp?.column));
				setColumnOrder(JSON.parse(defaultTemp?.columnOrder));
			}
			setTemplates(tableView.data?.data?.meeting);
		} else {
			setSelectedTemplate(null);
			setColumnVisibility({});
			setColumnOrder([]);
		}
	}, [tableView.data, templates]);

	const [updateTableView] = useUpdateTableViewMutation();

	const handleSetAsDefault = async (e) => {
		e.preventDefault();
		setLoading(true);
		const data = {
			default: true,
		};
		try {
			await updateTableView({ templateId: selectedTemplate?.id, data: data }).unwrap();
			setDefaultTemplate(selectedTemplate);
			setSelectedTemplate(selectedTemplate);
			setLoading(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Stack direction={isSmallScreen ? "column" : "row"} justifyContent={"center"} alignItems={"start"} width={"100%"} height={"100%"} spacing={2}>
			<Box
				width={"100%"}
				height={"max-content"}
				p={2}
				borderRadius={1.5}
				bgcolor={"#fff"}
				sx={{
					boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
				}}
			>
				<SectionHeader icon={<CiCalendarDate color="#164c68" size={18} />} title={t("MyMeetings")} section={"task"} />
				<Box height={"max-content"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
					<Box sx={{ width: "100%", height: "max-content" }}>
						<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} height={"max-content"} my={2}>
							{tabValue === "list" && (
								<>
									<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
										<Button
											size="small"
											startIcon={!isRtl && <MdOutlineAdd color="#fff" />}
											onClick={() => {
												dispatch(setIsCreatingMeeting(true));
												dispatch(setIsEditingMeeting(false));
												dispatch(setCurrentMeeting(null));
												navigate("/dashboard/meeting");
											}}
											sx={{
												bgcolor: "#164c68",
												color: "#fff",
												padding: 1,
												"&:hover": {
													bgcolor: "#00a3b1",
												},
												transition: "all 0.5s ease",
											}}
										>
											{t("CreateNew")}
										</Button>
									</Stack>
								</>
							)}
						</Stack>
						{isLoading && !isError ? (
							<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
								<CircularProgress />
							</Box>
						) : rows ? (
							<TabContext value={tabValue}>
								<Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
									<TabList onChange={handleChangeTab} aria-label="lab API tabs example">
										<Tab
											icon={<CiViewList color={tabValue === "list" ? "#0d6666" : "#1f2021"} size={20} />}
											iconPosition="start"
											label={t("ListView")}
											value="list"
										/>
										<Tab
											icon={<CiCalendarDate color={tabValue === "calendar" ? "#0d6666" : "#1f2021"} size={20} />}
											iconPosition="start"
											label={t("CalendarView")}
											value="calendar"
										/>
									</TabList>
								</Box>
								<TabPanel value="list" sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
									<Stack width={"100%"} direction={"column"} justifyContent={"flex-start"} alignItems={"start"} spacing={2}>
										<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
											<Typography variant={"h5"} color={"#164c68"}>
												{t("SelectedTemplate")}:
											</Typography>
											{templates?.length > 0 ? (
												<FormControl sx={{ m: 1, minWidth: 100, mr: isRtl ? "8px !important" : 0 }} size="small">
													<Select
														id="select-template"
														value={selectedTemplate}
														onChange={(e) => {
															setSelectedTemplate(e.target.value);
															setColumnVisibility(JSON.parse(e?.target?.value?.column));
															setColumnOrder(JSON.parse(e?.target?.value?.columnOrder));
														}}
														sx={{ color: "#164c68" }}
														displayEmpty
													>
														{defaultTemplate && (
															<MenuItem value={defaultTemplate}>
																{defaultTemplate?.name} ({t("Default")})
															</MenuItem>
														)}
														{templates
															?.filter((template) => defaultTemplate?.id !== template.id)
															.map((template) => (
																<MenuItem key={template.id} value={template}>
																	{template.name}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											) : (
												<Typography variant={"h5"} color={"#164c68"}>
													{t("NoTableViewFound")}
												</Typography>
											)}
											{selectedTemplate?.id !== defaultTemplate?.id && (
												<LoadingButton
													loading={isLoading}
													variant="contained"
													size="small"
													onClick={handleSetAsDefault}
													sx={{
														bgcolor: "#164c68",
														color: "#fff",
														padding: 1,
														"&:hover": {
															bgcolor: "#00a3b1",
														},
														transition: "all 0.5s ease",
													}}
												>
													{t("SetAsDefault")}
												</LoadingButton>
											)}
										</Stack>
										<ScrollableContainer>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<MeetingTable
													data={rows}
													totalCount={totalCount}
													setViewingEvent={setViewingEvent}
													setDeleteMeeting={() => setDeleteMeeting(true)}
													pagination={pagination}
													setPagination={setPagination}
													pageCount={Math.ceil(totalCount / pagination.pageSize)}
													searchQuery={searchQuery}
													setSearchQuery={setSearchQuery}
													colFilter={colFilter}
													setColFilter={setColFilter}
													columnVisibility={columnVisibility}
													setColumnVisibility={setColumnVisibility}
													columnSizing={columnSizing}
													setColumnSizing={setColumnSizing}
													columnOrder={columnOrder}
													setColumnOrder={setColumnOrder}
													setDefaultView={setDefaultTemplate}
													selectedTemplate={selectedTemplate}
													setSelectedTemplate={setSelectedTemplate}
													templates={templates}
													setHandleDeleteView={setHandleDeleteView}
												/>
											</LocalizationProvider>
										</ScrollableContainer>
									</Stack>
								</TabPanel>
								<TabPanel value="calendar" sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
									<MyCalendar events={calendarData} setViewingEvent={setViewingEvent} />
								</TabPanel>
							</TabContext>
						) : null}
					</Box>
				</Box>
			</Box>
			{deleteMeeting && <ConfirmDeleteDialog open={deleteMeeting} handleClose={() => setDeleteMeeting(false)} />}
			{handleDeleteView && (
				<ConfirmDeleteTableView
					open={handleDeleteView}
					handleClose={() => setHandleDeleteView(false)}
					templateId={selectedTemplate?.id}
					template={selectedTemplate}
					setTemplates={setTemplates}
					setSelectedTemplate={() => setSelectedTemplate(null)}
					type={"meeting"}
					setColumnVisibility={setColumnVisibility}
					setColumnOrder={setColumnOrder}
					setDefaultView={setDefaultTemplate}
				/>
			)}
		</Stack>
	);
};

export default MeetingSection;
